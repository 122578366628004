// components/vibes/Demo1.jsx
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { StateContext } from '../StateContextProvider';
import useChatAPI from '../../hooks/useChatAPI';
import ChatMessage from '../ChatMessage';
import TopMenu from '../TopMenu';  // Import TopMenu component
import BottomToolsMenu from '../BottomToolsMenu'; // Import BottomToolsMenu component
import './css/main.css';
import { setGeneralAiAgentEnabled, setUseWebsockets } from '../../utils/configuration';

const Demo1 = () => {
  // eslint-disable-next-line no-unused-vars
  const userInputRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // Add state for sidebar

  const {
    userInput, setUserInput,
    chatContent, setChatContent, currentSessionIndexRef,
    attachedImages, setAttachedImages,
    attachedFiles, setAttachedFiles,
    editingMessage, setEditingMessage,
    setFocusInput, endOfMessagesRef,
    errorMsg, setErrorMsg, isLastMessage,
    mScrollToBottom
  } = useContext(StateContext);

  // Custom hook for API calls
  const { callChatAPI } = useChatAPI();

  // Set the AI character for this demo when component mounts
  useEffect(() => {
    setChatContent((prevChatContent) => {
      const updatedChatContent = [...prevChatContent];
      updatedChatContent[currentSessionIndexRef.current].ai_character_name = 'assistant';
      return updatedChatContent;
    });
  }, [setChatContent, currentSessionIndexRef]);

  // Handle new chat click for TopMenu
  const handleNewChatClick = () => {
    setUserInput("");
    setAttachedImages([]);
    setAttachedFiles([]);
    setChatContent((prevChatContent) => {
      const updatedChatContent = [...prevChatContent];
      updatedChatContent[currentSessionIndexRef.current].messages = [];
      return updatedChatContent;
    });
  };

  // Handle send button click
  const handleSendClick = useCallback(() => {
    if (!userInput.trim() && attachedImages.length === 0 && attachedFiles.length === 0) {
      setErrorMsg("Please provide your input");
      return;
    }
    // always use agentic mode for vibes demos
    setGeneralAiAgentEnabled(true);

    // always use websockets for vibes demos
    //setUseWebsockets(true);
    setIsLoading(true);
    setErrorMsg('');

    // First, create a new chatContentSnapshot that we'll update before passing to the API
    let chatContentSnapshot = [...chatContent];

    // If editing a message, update it in our snapshot
    if (editingMessage !== null) {
      chatContentSnapshot[currentSessionIndexRef.current].messages[editingMessage] = {
        ...chatContentSnapshot[currentSessionIndexRef.current].messages[editingMessage],
        message: userInput,
        imageLocations: attachedImages.map(img => img.url),
        image_locations: attachedImages.map(img => img.url),
        fileNames: attachedFiles.map(file => file.url),
        fileLocations: attachedFiles.map(file => file.url)
      };

      // Now update the actual state for UI display
      setChatContent(chatContentSnapshot);

      // And call the API with our updated snapshot
      callChatAPI({
        chatContentSnapshot,
        editMessagePosition: editingMessage,
        isHealthMode: true // important - without it page will refresh to main app
      });
    } else {
      // For new messages, add to our snapshot first
      /*chatContentSnapshot[currentSessionIndexRef.current].messages.push({
        message: userInput,
        isUserMessage: true,
        dateGenerate: new Date().toISOString(),
        imageLocations: attachedImages.map(img => img.url),
        fileLocations: attachedFiles.map(file => file.url),
        aiCharacterName: chatContentSnapshot[currentSessionIndexRef.current].ai_character_name
      });*/

      // Update the state for UI display
      //setChatContent(chatContentSnapshot);

      // Call the API with our updated snapshot that already contains the new message
      callChatAPI({
        chatContentSnapshot,
        isHealthMode: true // important - without it page will refresh to main app
      });
    }

    // Reset states
    setUserInput("");
    setAttachedImages([]);
    setAttachedFiles([]);
    setFocusInput(true);
    setEditingMessage(null);
    setIsLoading(false);
  }, [userInput, chatContent, editingMessage, attachedImages, attachedFiles, callChatAPI, setUserInput, setAttachedImages, setAttachedFiles, setFocusInput, setEditingMessage, setErrorMsg, currentSessionIndexRef, setChatContent]);

  return (
    <div className="demo-layout">
      <TopMenu
        onNewChatClicked={handleNewChatClick}
        toggleSidebar={() => setIsSidebarVisible(!isSidebarVisible)}
        isVibesDemo={true}
      />

      <div className="demo-result-container">
        {chatContent[currentSessionIndexRef.current] && chatContent[currentSessionIndexRef.current].messages ? (
          <div className="demo-messages">
            {chatContent[currentSessionIndexRef.current].messages.map((message, index) => (
              <ChatMessage
                key={index}
                index={index}
                message={message}
                isLastMessage={isLastMessage(index, message)}
                contextMenuIndex={contextMenuIndex}
                setContextMenuIndex={setContextMenuIndex}
              />
            ))}
            <div ref={endOfMessagesRef} />
          </div>
        ) : (
          <div className="demo-empty-state">
            Enter a message above to start the conversation
          </div>
        )}
        {errorMsg && <div className="demo-error-msg">{errorMsg}</div>}
      </div>

      {/* Replace custom input area with BottomToolsMenu */}
      <div className="demo-bottom-tools-container">
        <BottomToolsMenu
          handleSendClick={handleSendClick}
          isFloating={false}
          mScrollToBottom={mScrollToBottom}
        />
      </div>
    </div>
  );
};

export default Demo1;
