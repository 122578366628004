import { triggerStreamingWSRequest } from '../services/api.websocket';
import {
  prepareChatHistory,
  prepareSettingsForWebsocketsRequest
} from './streamingUtils';

class AudioRecorder {
  constructor(options) {
    // Store config options
    this.getSettings = options.getSettings;
    this.onTranscriptionUpdate = options.onTranscriptionUpdate;
    this.onAiAnswerUpdate = options.onAiAnswerUpdate;
    this.onError = options.onError;
    this.onTTSFileUploaded = options.onTTSFileUploaded;

    // Current chat state
    this.chatContent = options.chatContent;
    this.currentSessionIndex = options.currentSessionIndex;

    // WebSocket connections
    this.audioWs = null;
    this.textWs = null;

    // State flags
    this.isRecording = false;
    this.isTranscriptionComplete = false;

    // Store the transcription text
    this.transcriptionText = '';

    // Debug
    console.log("AudioRecorder initialized with options:", {
      currentSessionIndex: this.currentSessionIndex,
      hasTranscriptionCallback: !!this.onTranscriptionUpdate,
      hasAiAnswerCallback: !!this.onAiAnswerUpdate
    });
  }

  async start() {
    try {
      // Start audio recording and streaming
      this.isRecording = true;
      await this.startAudioRecording();
    } catch (error) {
      console.error("Failed to start recording:", error);
      if (this.onError) {
        this.onError(error);
      }
    }
  }

  stop() {
    console.log("Stopping audio recording");
    this.isRecording = false;

    // Close WebSocket connections
    if (this.audioWs) {
      this.audioWs.close();
      this.audioWs = null;
    }

    // Don't close text WebSocket, let it finish streaming the AI response
  }

  async startAudioRecording() {
    console.log("Starting audio recording");

    try {
      // Get audio stream from user microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Create MediaRecorder to capture audio
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      // Create WebSocket for audio streaming
      this.audioWs = this.createAudioWebSocket();

      // Handle audio data
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && this.audioWs && this.isRecording) {
          audioChunks.push(event.data);
          this.audioWs.send(event.data);
        }
      };

      // Handle recording stop
      mediaRecorder.onstop = () => {
        console.log("MediaRecorder stopped");

        // Stop all tracks
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }

        // Send a final message to signal recording end if WebSocket is still open
        if (this.audioWs && this.audioWs.readyState === WebSocket.OPEN) {
          try {
            const finishPayload = JSON.stringify({ type: "RecordingFinished" });
            this.audioWs.send(finishPayload);
            console.log("Sent RecordingFinished signal");
          } catch (error) {
            console.error("Error sending recording finished signal:", error);
          }
        }
      };

      // Start recording
      mediaRecorder.start(250); // Collect data every 250ms

      // Create a stopper function
      this.stopAudioRecording = () => {
        console.log("stopAudioRecording called, state:", mediaRecorder.state);
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
      };
    } catch (error) {
      console.error("Error starting audio recording:", error);
      if (this.onError) {
        this.onError(error);
      }
    }
  }

  createAudioWebSocket() {
    console.log("Creating audio WebSocket");

    return triggerStreamingWSRequest({
      endpoint: "ws/chat",
      mode: "audio",
      userSettings: prepareSettingsForWebsocketsRequest(this.getSettings, "audio"),
      onOpen: (event) => {
        console.log("Audio WebSocket connection opened");
      },
      onMessage: (data) => {
        try {
          console.log("Audio WebSocket message received:", data);

          // Handle transcription updates
          if (data && typeof data === 'object') {
            if (data.type === "transcriptionInProgress" && data.transcriptionText) {
              this.transcriptionText = data.transcriptionText;
              console.log("Transcription update:", this.transcriptionText);

              // Update UI with transcription
              if (this.onTranscriptionUpdate) {
                this.onTranscriptionUpdate(this.transcriptionText);
              }
            }
            else if (data.type === "transcriptionComplete" && data.transcriptionText) {
              this.transcriptionText = data.transcriptionText;
              console.log("Transcription complete:", this.transcriptionText);

              // Update UI with final transcription
              if (this.onTranscriptionUpdate) {
                this.onTranscriptionUpdate(this.transcriptionText);
              }

              // Mark transcription as complete
              this.isTranscriptionComplete = true;

              // Stop the audio recording now that we have the transcription
              this.stopAudioRecording();

              // Start the text generation with the transcribed text
              this.startTextGeneration();
            }
          }
        } catch (error) {
          console.error("Error handling audio WebSocket message:", error);
        }
      },
      onError: (error) => {
        console.error("Audio WebSocket error:", error);
        if (this.onError) {
          this.onError(error);
        }
      },
      onClose: (event) => {
        console.log("Audio WebSocket closed");
      }
    });
  }

  startTextGeneration() {
    console.log("Starting text generation with transcription:", this.transcriptionText);

    try {
      if (!this.transcriptionText || this.transcriptionText.trim() === '') {
        console.warn("Empty transcription, not starting text generation");
        return;
      }

      // Prepare chat history and user prompt for text generation
      const chatHistory = prepareChatHistory(
        this.chatContent,
        this.currentSessionIndex
      );

      // Create a simulated user message with the transcribed text
      const simulatedUserPrompt = [
        { type: "text", text: this.transcriptionText }
      ];

      // Get character info from current session
      // eslint-disable-next-line no-unused-vars
      const currentSession = this.chatContent[this.currentSessionIndex];

      // Create the WebSocket for text generation
      console.log("Creating text WebSocket for AI response generation");
      this.textWs = triggerStreamingWSRequest({
        endpoint: "ws/chat",
        mode: "text",
        rawPayload: {
          requestType: "text",
          userInput: {
            prompt: simulatedUserPrompt,
            chat_history: chatHistory
          },
          assetInput: [],
          userSettings: prepareSettingsForWebsocketsRequest(this.getSettings, "text"),
          customerId: 1
        },
        onChunkReceived: (chunk) => {
          console.log("Received text chunk:", chunk);

          // Update UI with AI response
          if (this.onAiAnswerUpdate) {
            this.onAiAnswerUpdate(chunk);
          }
        },
        onStreamEnd: (fullResponse) => {
          console.log("Text generation complete");
        },
        onStreamingError: (error) => {
          console.error("Text generation error:", error);
          if (this.onError) {
            this.onError(error);
          }
        }
      });
    } catch (error) {
      console.error("Error starting text generation:", error);
      if (this.onError) {
        this.onError(error);
      }
    }
  }
}

export default AudioRecorder;
