/*
  This module implements WebSocket-based API methods.
  It provides methods to create and handle WebSocket connections
  for streaming requests, similar to how it's done in the Android app.
*/

import config from '../config';
import { getAuthTokenForBackend, setURLForAPICalls } from '../utils/configuration';

/*
  getWebSocketUrl:
  Constructs a WebSocket URL from a given endpoint by replacing the HTTP protocol with WS,
  and appending the authorization token as a query parameter if available.
*/
export const getWebSocketUrl = (endpoint) => {
  let wsUrl = endpoint.replace(/^http/, 'ws');
  console.log("wsUrl: ", wsUrl);
  const authToken = getAuthTokenForBackend();
  if (authToken) {
    wsUrl += (wsUrl.indexOf('?') === -1 ? '?' : '&') + 'token=' + authToken;
  }
  return wsUrl;
};

// Create a wrapper for triggerStreamingWSRequest specifically for text-based requests
export const triggerStreamingWSTextRequest = (options) => {
  const {
    endpoint,
    requestType,
    userInput,
    assetInput,
    getSettings,
    // eslint-disable-next-line no-unused-vars
    chatContent,
    // eslint-disable-next-line no-unused-vars
    currentSessionIndex,
    // eslint-disable-next-line no-unused-vars
    aiMessageIndex,
    // eslint-disable-next-line no-unused-vars
    editMessagePosition,
    attachedImages = [],
    attachedFiles = [],
    onChunkReceived,
    onStreamEnd,
    onStreamingError,
    onCustomEvent,
    onDBOperationExecuted
  } = options;

  // Get the user settings
  const userSettings = getSettings ? getSettings() : {};

  return triggerStreamingWSRequest({
    endpoint,
    mode: "text",
    requestType,
    userInput,
    assetInput,
    userSettings,
    attachedImages,
    attachedFiles,
    onChunkReceived,
    onStreamEnd,
    onStreamingError,
    onCustomEvent,
    onDBOperationExecuted
  });
};

const prepareFinalUserInput = (userInput, attachedImages, attachedFiles, chatHistory) => {
  // Ensure userInput is a string if it's not already
  const inputText = typeof userInput === 'string' ? userInput :
    (userInput.text ? userInput.text :
      (userInput.prompt && userInput.prompt.length > 0 && userInput.prompt[0].text ?
        userInput.prompt[0].text : JSON.stringify(userInput)));

  console.log("Formatted userInput as string:", inputText);

  const finalUserInput = {
    "prompt": [
      { "type": "text", "text": inputText },
      ...attachedImages.map(image => ({ "type": "image_url", "image_url": { "url": image.url } })),
      ...attachedFiles.map(file => ({ "type": "file_url", "file_url": { "url": file.url } })),
    ],
    "chat_history": (chatHistory.map((message) => ({
      "role": message.isUserMessage ? "user" : "assistant",
      "content": [
        { "type": "text", "text": typeof message.message === 'string' ? message.message : JSON.stringify(message.message) },
        ...((message.imageLocations || []).map(imageUrl => ({ "type": "image_url", "image_url": { "url": imageUrl } }))),
        ...((message.image_locations || []).map(imageUrl => ({ "type": "image_url", "image_url": { "url": imageUrl } }))),
        ...((message.fileNames || []).map(url => ({ "type": "file_url", "file_url": { "url": url } })))
      ]
    }))),
    // Add fields required by backend similar to Android format
    "userMessage": {
      "message": inputText,
      "isUserMessage": true,
      "imageLocations": attachedImages.map(image => image.url) || [],
      "fileNames": attachedFiles.map(file => file.url) || [],
      "dateGenerate": new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0].substring(0, 5)
    },
    "aiResponse": {
      "message": "",
      "isUserMessage": false,
      "imageLocations": [],
      "fileNames": [],
      "aiCharacterName": userInput.aiCharacterName || "assistant",
      "apiAIModelName": userInput.apiAIModelName || "",
      "dateGenerate": new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0].substring(0, 5)
    },
    "customer_id": 1,
    "session_id": userInput.session_id || ""
  };
  return finalUserInput;
}

/*
  triggerStreamingWSRequest:
  Creates a unified WebSocket connection for streaming API requests.
  It supports both text-based and audio-based streaming by parametrizing the mode.
  If a rawPayload is provided (for example pre-built via helper functions like
  prepareUserInputForWebsocketsRequest), then that payload will be sent as-is.

  For text mode (default):
    - Prepares the final user input (combining text and any attached media).
    - Sends a payload including requestType, userInput, assetInput, userSettings, and customerId.
    - Uses onChunkReceived, onStreamEnd, and onStreamingError callbacks.
  
  For audio mode:
    - Sets ws.binaryType to "arraybuffer" so that binary audio data is handled correctly.
    - Sends an initial payload with requestType "audio", assetInput, userSettings, and customerId.
    - Uses onOpen, onMessage, onError, and onClose callbacks.
  
  Options parameter should include:
    • endpoint: WebSocket endpoint (string)
    • mode: "text" (default) or "audio"

  Text mode options:
    • requestType, userInput, attachedImages, attachedFiles, userSettings,
      onChunkReceived, onStreamEnd, onStreamingError

  Audio mode options:
    • userSettings, customerId (default 1), onOpen, onMessage, onError, onClose
*/
export const triggerStreamingWSRequest = (options) => {
  const {
    endpoint,
    mode = "text",
    userSettings,
    customerId,
    rawPayload
  } = options;

  setURLForAPICalls();
  // Construct full endpoint URL
  const fullEndpoint = `${config.apiEndpoint}/${endpoint}`;
  const wsUrl = getWebSocketUrl(fullEndpoint);
  console.log(`Creating WebSocket connection to ${wsUrl} in ${mode} mode`);
  const ws = new WebSocket(wsUrl);

  if (mode === "audio") {
    // Ensure that binary data is handled correctly for audio
    ws.binaryType = "arraybuffer";
    console.log("Set WebSocket binaryType to arraybuffer for audio mode");
  }

  ws.onopen = (event) => {
    let payload;
    if (rawPayload) {
      // If rawPayload is provided, use it directly
      // We're assuming the caller has already structured it correctly
      payload = rawPayload;

      // Make sure we log full details of the raw payload for debugging
      console.log(`Using structured payload for ${mode} mode:`, payload);
    } else if (mode === "audio") {
      // Default payload for audio streaming.
      payload = {
        requestType: mode,
        assetInput: [],
        userSettings,
        customerId: customerId || 1
      };
    } else {
      // For text-based requests, prepare the final user input.
      const finalUserInput = prepareFinalUserInput(
        options.userInput,
        options.attachedImages,
        options.attachedFiles,
        []  // chatHistory can be provided if needed
      );

      // Make sure the userInput structure is expected by the backend
      payload = {
        requestType: options.requestType || "text",
        userInput: finalUserInput,
        assetInput: [],
        userSettings,
        customerId: 1
      };

      // Debug the final payload
      console.log("Final text WebSocket payload:", payload);
    }
    console.log(`${mode} WS connection opened. Sending payload:`, payload);
    ws.send(JSON.stringify(payload));
    if (mode === "audio" && options.onOpen) {
      options.onOpen(event);
    }
  };

  ws.onmessage = (event) => {
    let parsedData;
    try {
      // Parse the incoming data
      const rawData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      //console.log("Received WebSocket data:", rawData);

      // Handle different message types
      if (rawData && typeof rawData === 'object') {
        if (rawData.type === "text" && rawData.content !== undefined) {
          // Text type messages - only pass the content string for text UI updates
          parsedData = rawData.content;
        } else if (rawData.type === "dbOperationExecuted" && rawData.content) {
          // Handle database operation executed message from backend
          console.log("Database operation executed:", rawData.content);

          // Extract information from content (sessionId, userMessageId, aiMessageId)
          const dbResult = rawData.content;

          if (options.onDBOperationExecuted) {
            options.onDBOperationExecuted(dbResult);
          }
          return; // Skip regular message handling
        } else if (rawData.type === "transcriptionInProgress" || rawData.type === "transcriptionComplete") {
          // Transcription messages for audio processing - pass the entire object
          parsedData = rawData;
        } else if (rawData.type === "ttsNotRequested") {
          // Log but don't pass to display
          console.log("TTS not requested for this message");
          return; // Skip sending to handlers
        } else if (rawData.type === "ttsFileUploaded" && rawData.fileLocation) {
          // Handle TTS file upload
          console.log("TTS file uploaded:", rawData.fileLocation);
          // Could pass this to a TTS handler if needed
          if (mode === "audio" && options.onTTSFileUploaded) {
            options.onTTSFileUploaded(rawData.fileLocation);
          }
          return; // Skip sending to text handlers
        } else if (rawData.type === "streamingComplete" || rawData.type === "textCompleted") {
          // Handle streaming complete
          console.log("Streaming complete");
          if (options.onStreamEnd) {
            options.onStreamEnd(rawData);
          }
          return; // Skip sending to regular handlers
        } else if (rawData.type === "customEvent" && rawData.content && rawData.content.type === "image" && rawData.content.message === "imageGenerated") {
          // Handle custom image generation event
          console.log("Custom event received:", rawData.content);
          if (options.onCustomEvent) {
            options.onCustomEvent(rawData.content);
          }
          return; // Skip sending to regular handlers
        } else if (rawData.chunk !== undefined) {
          // Fallback for chunk format messages
          parsedData = rawData.chunk;
        } else {
          // For other object types, log them but don't pass them to text handlers
          console.log("Ignoring non-handled message type:", rawData.type || "unknown");
          return; // Skip sending to handlers
        }
      } else {
        // Not an object, use as is (e.g. binary data for audio mode)
        parsedData = rawData;
      }
    } catch (error) {
      // If it's not valid JSON, use the raw data
      console.log("Error parsing WebSocket data:", error);
      parsedData = event.data;
    }

    // Only call handlers if we have data to pass
    if (parsedData !== undefined) {
      if (mode === "audio") {
        if (options.onMessage) {
          options.onMessage(parsedData);
        }
      } else {
        if (options.onChunkReceived) {
          options.onChunkReceived(parsedData);
        }
      }
    }
  };

  ws.onerror = (event) => {
    console.error(`WebSocket error in ${mode} mode:`, event);
    if (mode === "audio") {
      if (options.onError) options.onError(event);
    } else {
      if (options.onStreamingError) options.onStreamingError(event);
    }
  };

  ws.onclose = (event) => {
    console.log(`WebSocket closed in ${mode} mode:`, event);
    if (mode === "audio") {
      if (options.onClose) options.onClose(event);
    } else {
      if (options.onStreamEnd) options.onStreamEnd(event);
    }
  };

  return ws;
};

/*
  createWebSocketConnection:
  Creates a generic WebSocket connection with provided event handlers.
  Returns the WebSocket instance for further communication (e.g., sending messages, closing connection).
*/
export const createWebSocketConnection = (url, { onOpen, onMessage, onError, onClose } = {}) => {
  const ws = new WebSocket(url);

  ws.onopen = (event) => {
    if (onOpen) {
      onOpen(event);
    }
  };

  ws.onmessage = (event) => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (error) {
      data = event.data;
    }
    if (onMessage) {
      onMessage(data);
    }
  };

  ws.onerror = (event) => {
    if (onError) {
      onError(event);
    }
  };

  ws.onclose = (event) => {
    if (onClose) {
      onClose(event);
    }
  };

  return ws;
}; 