// services/api.methods.js
import makeApiCall from './api.service';
import { formatDate } from '../utils/misc';
import config from "../config";
// eslint-disable-next-line no-unused-vars
import {
  prepareSettingsForWebsocketsRequest,
  prepareUserInputForWebsocketsRequest
} from '../utils/streamingUtils';
// eslint-disable-next-line no-unused-vars
import { triggerStreamingWSRequest, triggerStreamingWSTextRequest } from './api.websocket';

// Re-export the WebSocket functions
export { triggerStreamingWSRequest, triggerStreamingWSTextRequest };

export const prepareChatHistoryForDB = (chatContent) => {
  // prepare chat history for DB in expected format (same as android)
  const chatHistoryForDB = (chatContent.messages || []).map((message) => ({
    "message": message.message,
    "isUserMessage": message.isUserMessage,
    "imageLocations": message.imageLocations || [],
    "fileNames": message.fileNames || [],
    "aiCharacterName": message.aiCharacterName || "",
    "messageId": message.messageId || 0,
    "apiAIModelName": message.apiAIModelName,
    "dateGenerate": message.dateGenerate ? formatDate(message.dateGenerate) : null,
    "isTTS": message.isTTS || false,
    "showTranscribeButton": message.showTranscribeButton || false,
    "isGPSLocationMessage": message.isGPSLocationMessage || false
  }));
  return chatHistoryForDB;
}

export const triggerAPIRequest = async (endpoint, category, action, userInput, getSettings) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  try {
    const apiBody = {
      category: category,
      action: action,
      userInput: userInput,
      userSettings: getSettings(),
      customerId: 1,
    }
    const response = await makeApiCall({
      endpoint: API_BASE_URL,
      method: "POST",
      body: apiBody
    });

    return response;
  } catch (error) {
    console.error('Error triggering DB request:', error);
    throw error;
  }
};

export const triggerStreamingAPIRequest = async (endpoint, category, action, userInput, assetInput, getSettings, { onChunkReceived, onStreamEnd, onError }) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  const apiBody = {
    category: category,
    action: action,
    userInput: userInput,
    assetInput: assetInput,
    userSettings: getSettings(),
    customerId: 1,
  };

  try {
    await makeApiCall({
      endpoint: API_BASE_URL,
      method: 'POST',
      body: apiBody,
      streamResponse: true,
      onChunkReceived: onChunkReceived,
      onStreamEnd: onStreamEnd
    });
  } catch (error) {
    onError(error);
    console.error('Error during streaming:', error);
  }
}

/**
 * Triggers a WebSocket-based streaming text request.
 * This is similar to triggerStreamingAPIRequest but uses WebSockets instead of fetch.
 * 
 * @param {Object} options - Configuration options
 * @param {string} options.endpoint - WebSocket endpoint (e.g., "ws/chat")
 * @param {string} options.requestType - Type of request (e.g., "text")
 * @param {Object} options.userInput - User input data
 * @param {Array} options.assetInput - Asset input data
 * @param {Function} options.getSettings - Function to get user settings
 * @param {Function} options.onChunkReceived - Callback for received chunks
 * @param {Function} options.onStreamEnd - Callback for stream end
 * @param {Function} options.onStreamingError - Callback for errors
 * @returns {WebSocket} - The WebSocket instance
 */

// Helper function to generate UUID across browsers
// eslint-disable-next-line no-unused-vars
const generateUUID = () => {
  // Use crypto.randomUUID if available (modern browsers)
  if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  // Fallback implementation
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

export const updateSessionInDB = async (chatContentForSession, sessionId, getSettings, update_last_mod_time_in_db = true) => {
  //db_update_session to DB 
  const chatHistoryForDB = prepareChatHistoryForDB(chatContentForSession);
  const finalInputForDB = {
    "session_id": sessionId,
    "update_last_mod_time_in_db": update_last_mod_time_in_db,
    "chat_history": chatHistoryForDB
  }
  await triggerAPIRequest("api/db", "provider.db", "db_update_session", finalInputForDB, getSettings);
}

export const generateImage = async (image_prompt, getSettings) => {
  try {
    const userInput = { "text": image_prompt };
    const response = await triggerAPIRequest("generate", "image", "generate", userInput, getSettings);
    if (response.success) {
      return response.message.result;
    } else {
      throw new Error('Failed to generate image');
    }
  } catch (error) {
    console.error('Error generating image:', error);
    throw error;
  }
}

export const uploadFileToS3 = async (endpoint, category, action, getSettings, file) => {
  const API_BASE_URL = `${config.apiEndpoint}/${endpoint}`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', category);
  formData.append('action', action);
  formData.append('userInput', JSON.stringify({}));
  formData.append('userSettings', JSON.stringify(getSettings()));
  formData.append('customerId', 1);

  const response = await makeApiCall({
    endpoint: API_BASE_URL,
    method: 'POST',
    body: formData,
    headers: {}, // Ensure headers are set correctly for FormData
  });

  return response;
};

