/**
 * streamingUtils.js
 * 
 * This module provides utility functions for preparing data for streaming requests,
 * similar to the functions in the Kotlin StreamingUtils.kt file.
 */

// Import characters array from ChatCharacters
import { characters } from '../components/ChatCharacters';

/**
 * Helper function to generate UUID across browsers
 */
const generateUUID = () => {
  // Use crypto.randomUUID if available (modern browsers)
  if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  // Fallback implementation
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

/**
 * Prepares chat history for a streaming WebSocket request by formatting messages
 * properly for the backend.
 * 
 * @param {Array} chatContent - The full chat content array from the state context
 * @param {Number} currentSessionIndex - Index of the current active session
 * @param {Number|null} chatItemBeingEditedPosition - Position of the message being edited, if any
 * @returns {Array} - Formatted chat history array
 */
export const prepareChatHistory = (
  chatContent,
  currentSessionIndex,
  chatItemBeingEditedPosition = null,
) => {
  // Get messages from the current session
  const messages = chatContent[currentSessionIndex].messages;

  // If empty or it's a bug report, return empty array
  if (messages.length === 0) {
    return [];
  }

  // Determine how many messages to drop from the end
  let dropHowMany = 1;
  if (chatItemBeingEditedPosition !== null) {
    // If it's an edited message, we drop 2 messages if it's not the last message
    dropHowMany = chatItemBeingEditedPosition === messages.length - 1 ? 1 : 2;
  }

  // Prepare the chat history (excluding the latest messages)
  const chatHistory = messages
    .slice(0, messages.length - dropHowMany)
    .filter(msg => msg.message.trim() !== "")
    .map(msg => {
      if (msg.isUserMessage) {
        // Format user messages with text and any attached media
        const content = [
          { type: "text", text: msg.message }
        ];

        // Add images if any
        if (msg.imageLocations && msg.imageLocations.length > 0) {
          msg.imageLocations.forEach(imageUrl => {
            content.push({
              type: "image_url",
              image_url: { url: imageUrl }
            });
          });
        }

        // Add PDFs if any
        if (msg.fileNames && msg.fileNames.length > 0) {
          msg.fileNames.forEach(fileUri => {
            if (typeof fileUri === 'string' && fileUri.endsWith('.pdf')) {
              content.push({
                type: "file_url",
                file_url: { url: fileUri }
              });
            }
          });
        }

        return {
          role: "user",
          content: content
        };
      } else {
        // Format AI responses
        return {
          role: "assistant",
          content: msg.message
        };
      }
    });

  return chatHistory;
};

/**
 * Prepares the user prompt data for a streaming WebSocket request.
 * 
 * @param {Array} chatContent - The full chat content array from the state context
 * @param {Number} currentSessionIndex - Index of the current active session
 * @param {Number|null} chatItemBeingEditedPosition - Position of the message being edited, if any
 * @returns {Object} - Contains the userPrompt array and userActiveChatItem object
 */
export const prepareUserPrompt = (
  chatContent,
  currentSessionIndex,
  chatItemBeingEditedPosition = null
) => {
  const messages = chatContent[currentSessionIndex].messages;

  if (messages.length === 0) {
    return {
      userPrompt: [],
      userActiveChatItem: {
        message: "",
        isUserMessage: true,
        aiCharacterName: ""
      }
    };
  }

  // Get either the last message or the one being edited
  const userActiveChatItem = chatItemBeingEditedPosition !== null
    ? messages[chatItemBeingEditedPosition]
    : messages[messages.length - 1];

  // Prepare the user prompt with text and any attachments
  const userPrompt = [
    { type: "text", text: userActiveChatItem.message }
  ];

  // Add images if any
  if (userActiveChatItem.imageLocations && userActiveChatItem.imageLocations.length > 0) {
    userActiveChatItem.imageLocations.forEach(imageUrl => {
      userPrompt.push({
        type: "image_url",
        image_url: { url: imageUrl }
      });
    });
  }

  // Ensure we also look for image_locations property (with underscore)
  if (userActiveChatItem.image_locations && userActiveChatItem.image_locations.length > 0) {
    userActiveChatItem.image_locations.forEach(imageUrl => {
      userPrompt.push({
        type: "image_url",
        image_url: { url: imageUrl }
      });
    });
  }

  // Add PDFs if any
  if (userActiveChatItem.fileNames && userActiveChatItem.fileNames.length > 0) {
    userActiveChatItem.fileNames.forEach(fileUri => {
      if (typeof fileUri === 'string' && fileUri.endsWith('.pdf')) {
        userPrompt.push({
          type: "file_url",
          file_url: { url: fileUri }
        });
      }
    });
  }

  return {
    userPrompt,
    userActiveChatItem
  };
};

/**
 * Prepares the settings dictionary for a WebSocket request, including any character-specific settings.
 * 
 * @param {Function} getSettings - Function to get the current user settings
 * @param {String} requestType - Type of request (audio, text, tts)
 * @returns {Object} - Prepared settings dictionary
 */
export const prepareSettingsForWebsocketsRequest = (getSettings, requestType = "audio") => {
  // Get the settings dictionary from the user settings
  const settings = getSettings();

  // Make a deep copy of the settings
  const settingsDict = JSON.parse(JSON.stringify(settings));

  // Extract individual settings categories
  const ttsSettings = settingsDict.tts || {};
  const textSettings = settingsDict.text || {};
  const speechSettings = settingsDict.speech || {};
  const generalSettings = settingsDict.general || {};

  // Update all settings
  settingsDict.tts = ttsSettings;
  settingsDict.text = textSettings;
  settingsDict.speech = speechSettings;
  settingsDict.general = generalSettings;

  return settingsDict;
};

/**
 * Prepares the complete user input payload for a WebSocket request,
 * matching the Kotlin implementation in StreamingUtils.kt.
 * 
 * @param {Array} userPrompt - The user's message and any attachments
 * @param {Array} chatHistory - Formatted chat history
 * @param {String} sessionId - ID of the current session
 * @param {Object} userActiveChatItem - User message that is currently active
 * @param {Object} aiResponseChatItem - AI response placeholder
 * @param {Object|null} chatItemBeingEditedPosition - Position of message being edited, if any
 * @param {String|null} audioFileName - Name of audio file, if applicable
 * @param {String} characterName - The AI character name
 * @param {Boolean} autoTriggerTts - Whether to auto-trigger TTS
 * @param {String} aiTextGenModel - The AI text generation model
 * @returns {Object} - Complete payload for the WebSocket request
 */
export const prepareUserInputForWebsocketsRequest = (
  userPrompt,
  chatHistory,
  sessionId,
  userActiveChatItem,
  aiResponseChatItem,
  chatItemBeingEditedPosition = null,
  audioFileName = null,
  characterName = "assistant",
  autoTriggerTts = false,
  aiTextGenModel = "gpt-4o-mini"
) => {
  // Ensure we have valid chat history
  const validChatHistory = Array.isArray(chatHistory) ? chatHistory.filter(item => item !== null && item !== undefined) : [];

  // Check if the character has uiOption="image"
  const character = characters.find(char => char.nameForAPI === characterName);
  const hasImageUiOption = character && character.uiOption === "image";

  console.log("character:", character);
  console.log("hasImageUiOption:", hasImageUiOption);
  console.log("userPrompt:", userPrompt);

  // If character has uiOption="image", add image_mode to userPrompt
  if (hasImageUiOption && Array.isArray(userPrompt)) {
    // Add image_mode prompt item if it doesn't already exist
    const hasImageMode = userPrompt.some(item => item.type === "image_mode");
    if (!hasImageMode) {
      userPrompt.push({
        type: "image_mode",
        image_mode: "default"
      });
      console.log(`Added image_mode for character ${characterName} with prompt:`, userPrompt);
    }
  }

  console.log("userPrompt after:", userPrompt);

  // Ensure userMessage has all required fields matching Kotlin implementation
  const userMessage = {
    additionalInfoAfterPost: "",
    additionalInfoBeforePost: "",
    aiCharacterName: userActiveChatItem.aiCharacterName || "",
    dateGenerate: userActiveChatItem.dateGenerate || new Date().toISOString(),
    favorite: false,
    fileNames: userActiveChatItem.fileNames || [],
    imageLocations: userActiveChatItem.imageLocations || [],
    isGPSLocationMessage: userActiveChatItem.isGPSLocationMessage || false,
    isTTS: userActiveChatItem.isTTS || false,
    isUserMessage: true,
    localId: userActiveChatItem.messageId || generateUUID(),
    message: userActiveChatItem.message || "",
    offlineDataId: 0,
    showTranscribeButton: userActiveChatItem.showTranscribeButton || false
  };

  // Ensure aiResponse has all required fields
  const aiResponse = {
    additionalInfoAfterPost: "",
    additionalInfoBeforePost: "",
    aiCharacterName: characterName,
    apiTextGenAIModelName: aiTextGenModel,
    dateGenerate: aiResponseChatItem.dateGenerate || new Date().toISOString(),
    favorite: false,
    fileNames: aiResponseChatItem.fileNames || [],
    imageLocations: aiResponseChatItem.imageLocations || [],
    isGPSLocationMessage: false,
    isTTS: false,
    isUserMessage: false,
    localId: aiResponseChatItem.messageId || generateUUID(),
    message: aiResponseChatItem.message || "",
    offlineDataId: 0,
    showTranscribeButton: false
  };

  // Build the user input map matching the Kotlin implementation
  const userInputMap = {
    prompt: userPrompt || [],
    chat_history: validChatHistory,
    session_id: sessionId || "",
    userMessage: userMessage,
    aiResponse: aiResponse,
    auto_trigger_tts: autoTriggerTts,
    ai_text_gen_model: aiTextGenModel,
    new_ai_character_name: characterName,
    customer_id: 1,
    is_edited_message: chatItemBeingEditedPosition !== null,
    audio_file_name: audioFileName || ""
  };

  return userInputMap;
};

/**
 * Prepares a response chat item (empty AI message placeholder) for streaming updates.
 * 
 * @param {Array} chatContent - Full chat content array
 * @param {Number} currentSessionIndex - Current session index
 * @param {Number|null} chatItemBeingEditedPosition - Position of message being edited, if any
 * @param {String} characterNameForApi - Name of the AI character
 * @returns {Object} - Contains the message position and the chat item
 */
export const prepareResponseChatItem = (
  chatContent,
  currentSessionIndex,
  chatItemBeingEditedPosition = null,
  characterNameForApi = "assistant"
) => {
  // Format current date
  const messageDate = new Date().toISOString();

  let currentResponseItemPosition;
  let finalChatItem;

  if (chatItemBeingEditedPosition === null) {
    // This is a new message
    finalChatItem = {
      message: "",
      isUserMessage: false,
      aiCharacterName: characterNameForApi,
      apiTextGenAIModelName: "gpt-4o-mini", // Could be retrieved from config
      dateGenerate: messageDate,
      localId: generateUUID(),
      additionalInfoAfterPost: "",
      additionalInfoBeforePost: "",
      favorite: false,
      fileNames: [],
      imageLocations: [],
      isGPSLocationMessage: false,
      isTTS: false,
      offlineDataId: 0,
      showTranscribeButton: false
    };

    // In a real implementation, this would add the message to the session
    // For now, we'll just determine its position
    currentResponseItemPosition = chatContent[currentSessionIndex].messages.length;
  } else {
    // This is an edited message, replace the existing response
    currentResponseItemPosition = chatItemBeingEditedPosition + 1;
    const messages = chatContent[currentSessionIndex].messages;

    if (currentResponseItemPosition > messages.length - 1) {
      // If it's a new message after editing, create a new item
      finalChatItem = {
        message: "",
        isUserMessage: false,
        aiCharacterName: characterNameForApi,
        apiTextGenAIModelName: "gpt-4o-mini", // Could be retrieved from config
        dateGenerate: messageDate,
        localId: generateUUID(),
        additionalInfoBeforePost: "",
        additionalInfoAfterPost: "",
        favorite: false,
        fileNames: [],
        imageLocations: [],
        isGPSLocationMessage: false,
        isTTS: false,
        offlineDataId: 0,
        showTranscribeButton: false
      };
      // In a real implementation, this would add the message to the session
    } else {
      // Update the existing response
      finalChatItem = {
        ...messages[currentResponseItemPosition],
        message: "",
        aiCharacterName: characterNameForApi,
        apiTextGenAIModelName: "gpt-4o-mini", // Could be retrieved from config
        dateGenerate: messageDate,
        localId: messages[currentResponseItemPosition].messageId || generateUUID(),
        fileNames: [],
        additionalInfoBeforePost: "",
        additionalInfoAfterPost: "",
        favorite: false,
        imageLocations: [],
        isGPSLocationMessage: false,
        isTTS: false,
        offlineDataId: 0,
        showTranscribeButton: false
      };
      // In a real implementation, this would update the message in the session
    }
  }

  return {
    position: currentResponseItemPosition,
    chatItem: finalChatItem
  };
};