// components/VibesLayout.jsx
import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

//
const VibesLayout = () => {
  const location = useLocation();
  const isDemoPage = location.pathname.includes('demo1') || location.pathname.includes('demo2');

  return (
    <div>
      {!isDemoPage && (
        <>
          <h1>Vibes Demos</h1>
          {/* Optional: navigation for your demos */}
          <nav>
            <Link to="demo1">Demo 1 - YT</Link>
            <br />
            <Link to="demo2">Demo 2 - painting</Link>
          </nav>
        </>
      )}
      {/* Render the matching child route */}
      <Outlet />
    </div>
  );
};

export default VibesLayout;
